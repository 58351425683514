
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Why imonitor</h3>
            <!-- <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Why imonitor</li>
            </ul> -->
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <!-- <span class="sp-before sp-after">Why Choose imonitor?</span> -->
                        <h2>Revolutionizing Operations with Intelligence and Efficiency</h2>
                    </div>
                    <!-- <h3>We have 30 yearsOur strategy includes consistently evolving, to ensure we’re producing exceptional SEO for business.</h3> -->
                    <p>At imonitor, we understand the challenges businesses face in managing operations, ensuring compliance, and driving efficiency. That’s why we created imonitor, a web-based platform and mobile application designed to simplify your processes, provide real-time insights, and empower your team to make informed decisions.</p>
                    <!-- <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check bgOurColor'></i>
                                <span>01</span>
                                <p>High standards of professionalism, integrity. Establishment of close working relationships.</p>
                            </li>
                            <li>
                                <i class='bx bx-check bgOurColor'></i>
                                <span>02</span>
                                <p>A different way of thinking, both inside and outside the box. We do not have to be the best.</p>
                            </li>
                            <li>
                                <i class='bx bx-check bgOurColor'></i>
                                <span>03</span>
                                <p>We do not have to be the best, we do have to give our best Ability to learn. Multicultural competence.</p>
                            </li>
                        </ul>
                    </div> -->
                    <div class="about-video">
                        <a href="../../../../../assets/imonitor2.mp4" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span class="sp-before sp-after">Services</span> -->
            <h2>Here’s why imonitor is the right choice for your business</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6 col-md-6">
                <div class="service-list-content">
                    <h3>Comprehensive Solutions in One Platform</h3>
                    <p>imonitor consolidates your operational needs into one intuitive platform. Say goodbye to scattered tools and streamline your processes effortlessly. </p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Creating Results.</li>
                        <li><i class='bx bx-check bgOurColor'></i> Good Thinking</li>
                        <li><i class='bx bx-check bgOurColor'></i> Expect More</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="service-list-content">
                    <h3>Real-Time Data Collection and Analysis</h3>
                    <p>With imonitor, you can collect and analyze data in real time, enabling quick responses to issues and proactive decision-making. Whether you’re in the office or on the go, the platform keeps you connected with the information that matters.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> We Have You Covered</li>
                        <li><i class='bx bx-check bgOurColor'></i> Stay Real Always</li>
                        <li><i class='bx bx-check bgOurColor'></i> We Turn Heads</li>
                        <li><i class='bx bx-check bgOurColor'></i> Stay Real Always</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Customizable for Your Needs</h3>
                    <p>No two businesses are the same. That’s why imonitor offers customizable workflows, checklists, and reporting features to match your unique operational requirements.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                        <li><i class='bx bx-check bgOurColor'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>








            <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Multi language Support</h3>
                    <p>Expand your capabilities with a platform designed for diverse teams. With full support for English and Arabic, imonitor ensures smooth communication and usability for all your team members.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                        <li><i class='bx bx-check bgOurColor'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Enhanced Compliance and Accountability</h3>
                    <p>Stay ahead of industry standards and regulations with automated compliance tracking and detailed audit-ready reports. imonitor helps you reduce risks, avoid penalties, and maintain a strong reputation.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                        <li><i class='bx bx-check bgOurColor'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Scalable and Versatile</h3>
                    <p>Whether you’re a small business or a multinational corporation, imonitor scales with you. It’s ideal for a range of industries, including food service, manufacturing, hospitality, healthcare, and more.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                        <li><i class='bx bx-check bgOurColor'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Data Security and Reliability</h3>
                    <p>We prioritize your data’s safety. imonitor employs robust security protocols to protect sensitive information and ensure uninterrupted access. </p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                        <li><i class='bx bx-check bgOurColor'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>IoT Integration (Coming Soon)</h3>
                    <p>Leverage the power of IoT with imonitor’s upcoming integration. Automate data collection, monitor equipment in real-time, and unlock new levels of efficiency.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                        <li><i class='bx bx-check bgOurColor'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Actionable Insights at Your Fingertips</h3>
                    <p>Turn data into results with imonitor’s advanced analytics. Monitor trends, identify opportunities for improvement, and make data-driven decisions that drive growth.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                        <li><i class='bx bx-check bgOurColor'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Save Time and Reduce Costs</h3>
                    <p>By automating repetitive tasks and minimizing errors, imonitor helps you focus on what matters most—growing your business.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                        <li><i class='bx bx-check bgOurColor'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check bgOurColor'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check bgOurColor'></i> Activate Listening</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Frame the Problem <span>1</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Collect the Raw Data <span>2</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Process the Data <span>3</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Explore the Data</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>5</span>Perform Analysis</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>6</span>Communicate Results</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span class="sp-before sp-after">Our History</span> -->
            <h2>The Facts About Our Company</h2>
        </div>

        <!-- <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check bgOurColor'></i>
                    <div class="content">
                        <h3>2010</h3>
                        <span>February 20th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check bgOurColor'></i>
                    <div class="content">
                        <h3>2012</h3>
                        <span>January 14th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check bgOurColor'></i>
                    <div class="content">
                        <h3>2016</h3>
                        <span>March 25th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check bgOurColor'></i>
                    <div class="content">
                        <h3>2018</h3>
                        <span>December 10th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check bgOurColor'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span>February 01th</span>
                    </div>
                </li>
            </ul>
        </div> -->

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Trusted by Industry Leaders</h2>
                    <p>Join a growing community of forward-thinking businesses that trust monitor to transform their operations. </p>
                   
                    <h2>Experience the Difference with imonitor</h2>
                    <p>Ready to optimize your operations and achieve more with less effort? Choose an imonitor and unlock the full potential of your business. </p>

                    <a routerLink="/about" class="default-btn">About us<i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/home-three/home-three-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 
<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

