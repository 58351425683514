
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>FAQ</h3>
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>FAQ</li>
            </ul> -->
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="faq-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">FAQ</span>
            <h2 class="h2-color2">Frequently Asked Questions</h2> 
            <p>Everything You Need to Know About imonitor
                At the Imonitor, we aim to provide clarity and transparency to our platform and services. Below, you will find answers to some of the most commonly asked questions about the imonitor.
                </p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> What is imonitor?</a>
                            <div class="accordion-content">
                                <p>imonitor is a web-based platform and mobile application that helps businesses collect, analyze, and manage operational data. It supports areas such as food safety, Health and Safety, equipment servicing, sustainability, and risk management, and offers real-time insights to enhance efficiency and compliance.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Who can use imonitor?</a>
                            <div class="accordion-content">
                                <p>imonitors are designed for a wide range of industries, including
                                    <br>
                                    •⁠  ⁠Food service and hospitality
                                    <br>

                                    •⁠  ⁠Manufacturing and production
                                    <br>

                                    •⁠  ⁠Healthcare facilities
                                    <br>

                                    •⁠  ⁠Corporate offices
                                    <br>

                                    •⁠  ⁠Gas stations and retail outlets
                                    <br>

                                    Whether you are a small business or a multinational corporation, does the imonitor adapt to your needs?
                                    </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> What features does an imonitor offer?</a>
                            <div class="accordion-content">
                                <p>Some key features of an imonitor include the following.
                                    <br>
                                    •⁠  ⁠Real-time data collection and analysis
                                    <br>
                                    •⁠  ⁠Customizable workflows and checklists
                                    <br>
                                    •⁠  ⁠Automated reporting and compliance tracking
                                    <br>
                                    •⁠  ⁠Multilingual support (English and Arabic)
                                    <br>
                                    •⁠  ⁠Integration with IoT devices (coming soon)</p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Is an imonitor available on mobile devices?</a>
                            <div class="accordion-content ">
                                <p>Yes! imonitor is accessible on both web-based platforms and mobile applications, ensuring that it can be used anytime and anywhere.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Can I customize to meet my business needs?</a>
                            <div class="accordion-content">
                                <p>Absolutely. imonitor offers extensive customization options, including tailored workflows, checklists, and reports to match specific operational requirements.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Does an imonitor support multiple locations?</a>
                            <div class="accordion-content">
                                <p>Yes, an imonitor was built to scale. To manage one site or multiple locations across different regions, all operations can be centralized and imonitored on a single platform.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> How secure is imonitor?</a>
                            <div class="accordion-content ">
                                <p>We prioritize data security and use advanced encryption protocols to protect information. Regular updates and backups ensured that data remained safe and accessible.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> ⁠What languages does the imonitor support?
                            </a>
                            <div class="accordion-content">
                                <p>imonitor is fully supported in both English and Arabic, making it ideal for diverse teams and regions.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> What are the benefits of using an imonitor?
                            </a>
                            <div class="accordion-content">
                                <p>Using an imonitor, we can
                                    <br>
                                    •⁠  ⁠Improve operational efficiency
                                    <br>
                                    •⁠  ⁠Ensure compliance with industry regulations
                                    <br>
                                    •⁠  ⁠Reduce manual errors and save time
                                    <br>
                                    •⁠  ⁠Gain actionable insights through real-time analytics
                                    <br>
                                    •⁠  ⁠Enhance communication across teams and locations
                                    </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> ⁠Can I try an imonitor before committing it?</a>
                            <div class="accordion-content">
                                <p>Yes! We offer a demo to help explore the features and benefits of an imonitor. contacted us to schedule a personalized walk-through of the platform.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> ⁠What is the pricing structure?</a>
                            <div class="accordion-content ">
                                <p>Pricing for an imonitor is based on specific needs, including the size of the operations, the number of users, and the features required. Contact the sales team for a customized quote.</p>
                            </div>
                        </li>


                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> How does I start with the imonitor?</a>
                            <div class="accordion-content">
                                <p>Getting started is easy!
                                    <br>
                                     1.⁠ ⁠Contact Us: Reach out to discuss your needs and schedule a demo.
                                    <br>
                                     2.⁠ ⁠Onboarding: Our team will guide you through the setup process.
                                    <br>
                                     3.⁠ ⁠Start imonitor: Begin optimizes operations with an imonitor.</p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Does an imonitor provide user training?</a>
                            <div class="accordion-content">
                                <p>Yes, we offer comprehensive training during onboarding and access to a library of tutorials and videos to help you make the most of imonitor.
                                    <br>
                                    14.⁠ ⁠How often is imonitor updated?
                                    We regularly update imonitor to improve functionality, enhance security, and introduce new features. Updates are automatic and seamless, so you’ll always have the latest version.</p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> Have More Questions?</a>
                            <div class="accordion-content ">
                                <p>We’re here to help! If you didn’t find the answer you were looking for, please Contact Us, and one of our team members will get back to you shortly.</p>
                            </div>
                        </li>
                    </ul>
                </div>   
            </div>
        </div>
    </div>
</div>

<div class="faq-contact pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">FAQ</span>
            <h2>Tell Us Any Questions</h2>
        </div>

        <div class="row pt-45 align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="faq-img">
                    <img src="assets/img/faq-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="faq-contact-wrap">
                    <div class="contact-form">
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" name="name" id="name" class="form-control" placeholder="Your Name*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-file'></i>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="shape1"></div>

<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/facebook_profile_image.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/ICS Logo.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

