import { Component } from '@angular/core';

@Component({
  selector: 'app-why-imonitor',
  templateUrl: './why-imonitor.component.html',
  styleUrl: './why-imonitor.component.scss'
})
export class WhyImonitorComponent {

}
