<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Pricing Plan</h3>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-50 pb-100">
    <div class="container-fluid">
        <div class="section-title text-center">
            <h4 class="h2-color">Flexible Plans for Every Business <br>
                We understand that every business is unique,<br> which is why imonitor offers tiered pricing options tailored to your needs.
            </h4>
            <br>
            <h4>Start with a free 14-day trial</h4>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card h-100">
                        <div class="pricing-card-into color-bg1 h-100 ">
                            <i class="flaticon-banner pricing-icon color-1 text-center"></i>
                            <h3 class="color-1 text-center">Basic Plan</h3>
                            <!-- <div class="price-rate">
                                <h2 class="color-1">$ 69.99</h2>
                                <span class="color-1">Monthly Package</span>
                            </div> -->
                            <ul class="txtLeft">
                                <li class="liC1"> Ideal for small teams starting with digital audits and data collection</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i>  it includes essential features like mobile access</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i>  Free checklist building</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i> 2 Active audit module</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i> Add up to 10 users</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i> Add up to 2 Branches</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i>  Action Plan</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i>  Assign and complete tasks</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i>  Real Time Notification</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i> basic reporting</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i>Unlimited cloud storage space</li>
                                <li class="liC1"><i class='bx bx-check liC1'></i> 3 years' worth of data and evidence</li>
                                
                                <li class="liC1"><i class='bx bx-check liC1'></i> 24/7 Support</li>
                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg1">Contact Us</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card h-100">
                        <div class="pricing-card-into color-bg2 h-100">
                            <i class="flaticon-mortgage-loan pricing-icon color-3 "></i>
                            <h3 class="color-3 text-center">Standard Plan</h3>
                            <!-- <div class="price-rate">
                                <h2 class="color-2">$ 79.99</h2>
                                <span class="color-2">Monthly Package</span>
                            </div> -->
                            <ul class="txtLeft">
                                <li class="liC2"> Perfect for growing businesses needing advanced analytics</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i>  it includes essential features like mobile access</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i>  Free checklist building</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i> 5 Active audit module</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i> Add up to 30 users</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i> Add up to 5 Branches</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i>  Action Plan</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i>  Assign and complete tasks</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i>  Real Time Notification</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i> basic reporting</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i>Unlimited cloud storage</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i> Custom dashboard</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i> IoT device integration</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i> Unlimited of data and evidence</li>
                                <li class="liC2"><i class='bx bx-check liC2'></i> 24/7 Support</li>

                                
                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg2">Contact Us</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card h-100">
                        <div class="pricing-card-into color-bg3 h-100">
                            <i class="flaticon-processing pricing-icon color-2"></i>
                            <h3 class="color-2 text-center">Enterprise Plan</h3>
                            <!-- <div class="price-rate">
                                <h2 class="color-3">$ 89.99</h2>
                                <span class="color-3">Monthly Package</span>
                            </div> -->
                            <ul class="txtLeft">
                                <li class="liC3"> Large organizations require a fully customized solution</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i>  it includes essential features like mobile access</li>
                                  <li class="liC3"><i class='bx bx-check liC3'></i>  Free checklist building</li>
                                  <li class="liC3"><i class='bx bx-check liC3'></i> Unlimited Active audit module</li>
                                  <li class="liC3"><i class='bx bx-check liC3'></i> Unlimited users</li>
                                  <li class="liC3"><i class='bx bx-check liC3'></i> Unlimited Branches</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i>  Action Plan</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i>  Assign and complete tasks</li>
                                 <li class="liC3"><i class='bx bx-check liC3'></i>  Provide fundamental workplace training</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i>  Real Time Notification</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i> basic reporting</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i> Unlimited cloud storage</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i> Custom dashboard</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i> IoT device integration</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i> Dedicated support</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i> Multi-location capabilities</li>
                                <li class="liC3"><i class='bx bx-check liC3'></i> 24/7 Support</li>

                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg3">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>
