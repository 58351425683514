import { Component } from '@angular/core';

@Component({
  selector: 'app-feauters',
  standalone: true,
  imports: [],
  templateUrl: './feauters.component.html',
  styleUrl: './feauters.component.scss'
})
export class FeautersComponent {

}
