<div class="preloader">
    <div class="d-table">
        <div class="d-table-cell text-center">
            <!-- <div class="spinner"></div> -->
            <img style="animation: movebounce-up 1.2s infinite ease-in-out;" width="150" src="assets/img/logo/FINAL_RGB-02.png" alt="Logo">

            
            <!-- <svg class="loader-image" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="200" height="200">
                <style>
                  /* Keyframe animation to make paths appear one by one */
                  @keyframes show {
                    0% {
                      opacity: 0;
                    }
                    50% {
                      opacity: 1;
                    }
                    100% {
                      opacity: 0;
                    }
                  }
            
                  /* Apply animation to each path */
                  path {
                    opacity: 0; /* Start hidden */
                    animation: show 1.5s ease-in-out infinite; /* Each path fades in and out infinitely */
                  }
            
                  /* Stagger the animation delays for each path */
                  .a {
                    animation-delay: 0.3s;
                  }
            
                  .b {
                    animation-delay: 0.6s;
                  }
            
                  .c {
                    animation-delay: 0.9s;
                  }
            
                  .d {
                    animation-delay: 1.2s;
                  }
            
                  .e {
                    animation-delay: 1.5s;
                  }
                </style>
            
                <path class="a" fill="#194759" d="m8.8 8.1l-3.5 3.4 1 1 3.5-3.5z" />
                <path class="b" fill="#194759" d="m6 10.3l-1 0.9-2-2h1.9z" />
                <path class="c" fill="#f0552f" d="m13.7 8.8l-5.4-5.3-5.3 5.3h1.9l3.4-3.4 3.5 3.4z" />
                <path class="d" fill="#f0552f" d="m11.8 9.2l-2.5 2.5 1 0.9 3.4-3.4z" />
                <path class="e" fill="#f0552f" d="m7.4 13.6l0.9 0.9 1.7-1.6-0.9-1z" />
              </svg> -->
        </div>
    </div>
</div>