import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {  HomeComponent } from './components/pages/Home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { WhyImonitorComponent } from './components/pages/why-imonitor/why-imonitor/why-imonitor.component';
import { FeautersComponent } from './components/pages/feauters/feauters.component';
import { IndustriesComponent } from './components/pages/industries/industries.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'home', component: HomeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'why-imonitor', component: WhyImonitorComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'contact', component: ContactComponent},
    {path:'feauters',component:FeautersComponent},
    {path:'industries',component:IndustriesComponent},
    {path:'pricing',component:PricingComponent},

    

    // {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }