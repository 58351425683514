<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Industires</h3>
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul> -->
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>


<div class="about-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/Building safety-pana.png" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <!-- <span class="sp-after">About us</span> -->
                            <h2 class="h2-color">Construction</h2>
                        </div>
                        <p>• Monitor and manage workplace safety audits, ensuring compliance with health and safety regulations.
                            <br>
                            • Track equipment maintenance schedules to prevent costly downtimes.
                            <br>
                            • Conduct inspections to maintain quality and safety standards on construction sites.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Food and Beverage</h2>
                        </div>
                        <p>• Streamline HACCP monitoring, food safety audits, and temperature tracking.
                            <br>
                            • Ensure hygiene compliance through cleaning and sanitizing schedules.
                            <br>
                            • Analyze trends in product quality and supplier performance to optimize operations.
                        </p>
                      
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/Food.png" alt="Images">
                    </div>
                </div>

               
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/factories.png" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Factories</h2>
                        </div>
                        <p>• Conduct equipment inspections and track maintenance for smooth production processes.
                            <br>
                            • Monitor quality assurance protocols to meet industry standards.
                            <br>
                            • Manage compliance audits for safety, hygiene, and environmental regulations.</p>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Healthcare</h2>
                        </div>
                        <p>• Ensure compliance with strict hygiene and safety standards through routine audits.
                            <br>
                            • Monitor temperature-controlled environments for sensitive medical supplies.
                            <br>
                            • Track lab analysis reports to ensure consistency and regulatory compliance.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/health care.png" alt="Images">
                    </div>
                </div>

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/Hotel Booking-rafiki.png" alt="Images">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Hospitality</h2>
                        </div>
                        <p>• Conduct comprehensive audits to monitor guest safety, facility hygiene, and service quality.
                            <br>
                            • Streamline cleaning and maintenance schedules to ensure a seamless guest experience.
                            <br>
                            • Use data insights to enhance customer satisfaction and operational efficiency.</p>
                    </div>
                </div>
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Gas Stations</h2>
                        </div>
                        <p>• Monitor safety and environmental compliance for hazardous materials.
                            <br>
                            • Conduct regular audits of fueling equipment, storage tanks, and facility cleanliness.
                            <br>
                            • Track trends and implement preventative measures to mitigate risks.</p>
                    </div>
                </div>
              
<div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/gas-station.png" alt="Images">
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/facility managment.png" alt="Images">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Facility Management</h2>
                        </div>
                        <p>• Manage and track cleaning schedules, equipment maintenance, and safety audits.
                            <br>
                            • Ensure compliance with regulations for various facilities, from offices to residential complexes.
                            <br>
                            • Centralize operational data for streamlined management and reporting.</p>
                    </div>
                </div>
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Retail</h2>
                        </div>
                        <p>• Monitor store cleanliness, safety protocols, and staff compliance.
                            <br>
                            • Track storage temperatures to maintain product integrity.
                            <br>
                            • Use trend analysis to improve customer experience and operational efficiency.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/retail.png" alt="Images">
                    </div>
                </div>
             
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/education.png" alt="Images">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Educational Institutions</h2>
                        </div>
                        <p>• Conduct safety inspections to ensure the health and safety of students and staff.
                            <br>
                            • Monitor cleaning and maintenance schedules for classrooms, labs, and facilities.
                            <br>
                            • Ensure compliance with local and international educational standards.</p>
                    </div>
                </div>
            
             
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
         
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Logistics and Warehouses</h2>
                        </div>
                        <p>• Track storage conditions, including temperature and humidity, to preserve product quality.
                            <br>
                            • Conduct regular audits to ensure safety and compliance across facilities.
                            <br>
                            • Optimize supply chain operations through actionable insights and trend analysis.</p>
                    </div>
                </div>
            
                    <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="warehouseWidth" src="assets/img/industries/warehouse.png" alt="Images">
                    </div>
                </div>
              

                
            </div>
        </div>
    </div>


    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
          <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/Office management-cuate.png" alt="Images">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Corporate Offices</h2>
                        </div>
                        <p>• Monitor health and safety protocols for employees and visitors.
                            <br>
                            • Track facility maintenance and cleaning schedules.
                            <br>
                            • Centralize compliance documentation and audit results for streamlined management.</p>
                    </div>
                </div>
            
                   
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">   
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Catering</h2>
                        </div>
                        <p>• Ensure food safety compliance through real-time temperature tracking and cleaning schedules.
                            <br>
                            • Monitor quality assurance processes to deliver consistent service.
                            <br>
                            • Manage supplier audits to maintain high-quality sourcing standards.</p>
                    </div>
                </div>
            
          <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/Catering service-bro.png" alt="Images">
                    </div>
                </div>
             
                   
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">   
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/health and safety.png" alt="Images">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Health and Safety</h2>
                        </div>
                        <p>• Conduct workplace safety audits to ensure compliance with local and international regulations.
                            <br>
                            • Automate corrective actions for identified risks and non-compliance.
                            <br>
                            • Use insights to improve safety culture and minimize incidents.</p>
                    </div>
                </div>
               
              
            
         
             
                   
              

                
            </div>
        </div>
    </div>


    <div class="container-fluid">
        <div class="container-max">
            <div class="row">   
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Mystery Shopping</h2>
                        </div>
                        <p>• Conduct detailed inspections to assess service quality and customer experience.
                            <br>
                            • Generate comprehensive reports with photos and insights to drive improvements.
                            <br>
                            • Identify operational gaps and train staff based on actionable data.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/mystry.png" alt="Images">
                    </div>
                </div>
                
             
              
            
         
             
                   
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">   
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/Gym-rafiki.png" alt="Images">
                    </div>
              
             
               
                </div>
            
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Gyms and Fitness Centers</h2>
                        </div>
                        <p>• Monitor hygiene and safety standards across workout areas and facilities.
                            <br>
                            • Conduct regular inspections for equipment maintenance and cleanliness.
                            <br>
                            • Use reports and dashboards to enhance member satisfaction and compliance.</p>
                    </div>
                </div>
             
                   
              

                
            </div>
        </div>
    </div>


    <div class="container-fluid">
        <div class="container-max">
            <div class="row">   
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Supplier Audits</h2>
                        </div>
                        <p>• Evaluate supplier performance with tailored audit checklists.
                            <br>
                            • Ensure compliance with contractual and regulatory standards.
                            <br>
                            • Track trends and build stronger supplier relationships through actionable insights.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/supplier.png" alt="Images">
                    </div>
                </div>
               
          
            
         
             
                   
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">   
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/covid.png" alt="Images">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">COVID-19 and POSI (Prevention of Spread of Infection) Systems</h2>
                        </div>
                        <p>• Monitor compliance with COVID-19 safety protocols and guidelines.
                            <br>
                            • Ensure proper sanitization and hygiene through real-time audits and tracking.
                            <br>
                            • Use automated tools to adapt to evolving safety regulations and minimize risks.</p>
                    </div>
                </div>
          
            
       
             
                   
              

                
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row">   
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Property Inspections</h2>
                        </div>
                        <p>• Conduct detailed property inspections for commercial and residential spaces.
                            <br>
                            • Generate comprehensive reports for compliance, maintenance, and investment decisions.
                            <br>
                            • Monitor trends to optimize property management and value retention.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/Property.png" alt="Images">
                    </div>
                </div>
               
          
            
        
             
                   
              

                
            </div>
        </div>
    </div>


    <div class="container-fluid">
        <div class="container-max">
            <div class="row">  
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/Energy.png" alt="Images">
                    </div>
                </div> 
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color"> Energy Management</h2>
                        </div>
                        <p>• Track and monitor energy usage across facilities.
                            <br>
                            • Analyze trends to identify inefficiencies and optimize resource utilization.
                            <br>
                            • Ensure compliance with environmental and energy-saving regulations.</p>
                    </div>
                </div>
              
               
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-max">
            <div class="row"> 
                <div class="col-lg-6 col-md-12 align-content-center">
                    <div class="about-content">
                        <div class="section-title">
                            <h2 class="h2-color">Sustainability</h2>
                        </div>
                        <p>• Monitor and manage corporate sustainability goals and initiatives.
                            <br>
                            • Conduct audits for energy, waste, and water usage to ensure eco-friendly practices.
                            <br>
                            • Use insights to enhance sustainability efforts and achieve compliance.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 CenterImg">
                    <div class="about-img fullImgWidth">
                        <img class="imgWidth" src="assets/img/industries/sustainability.png" alt="Images">
                    </div>
                </div>  
             
               
               
            </div>
        </div>
    </div>
    <div class="about-shape">
        <div class="shape-1"><img src="assets/img/about/about-shape1.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape2.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape3.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
    </div>
</div>
