
<div class="banner-area-two">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="banner-content-two mt-5">
                        <span>Best for You</span>
                        <h1>We’re <b>Your Partner</b> in <b>Excellence</b> Journey</h1>
                        <p class="fsXlarge">Streamline operations, enhance understanding. 
                            Optimize productivity with our smartphone-based auditing tool and concentrate on essential tasks.
                            </p>
                        <div class="banner-content-btn">
                            <a href="/about" class="learn-btn">Learn More <i class='bx bx-plus'></i></a>
                            <a href="../../../../assets/imonitor.mp4" class="play-on-btn popup-btn">Play Video <i class='flaticon-forward'></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="banner-img-2">
                        <img src="assets/img/home-two/home-two-img.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-bottom-shape">
        <div class="shape-one wow fadeInUp" data-wow-delay=".3s"><img src="assets/img/home-two/home-two-shape1.png" alt="Images"></div>
        <div class="shape-two wow fadeInUp" data-wow-delay=".5s"><img src="assets/img/home-two/home-two-shape2.png" alt="Images"></div>
        <div class="shape-three wow fadeInUp" data-wow-delay=".7s"><img src="assets/img/home-two/home-two-shape3.png" alt="Images"></div>
        <div class="banner-dots1"><img src="assets/img/home-two/home-two-dots.png" alt="Images"></div>
        <div class="banner-dots2"><img src="assets/img/home-two/home-two-dots2.png" alt="Images"></div>
    </div>
</div>


<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center mt-5">
                <div class="col-lg-5 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/about-img2.png" alt="">
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <!-- <span class="sp-before sp-after">About Us</span> -->
                            <h2 class="noneMaxwidth">A user-friendly application for on-the-move data collection
                            </h2>
                        </div>
                        <h3>Eliminate extra documentation or spreadsheet usage. Perform mobile inspections on your chosen device, even without internet connectivity. Efficiently document every aspect: Our clients report inspection speeds up to 10 times faster</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tetur adipisicing saliqua.</p> -->
                        <div class="row">
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <span class="numbersML">01</span>
                                    <p>⁠Tailored digital checklist</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <span  class="numbersML">02</span>
                                    <p>⁠images, markups, digital signature</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <span  class="numbersML">03</span>
                                    <p>⁠Smartphone, tablet & compute</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <span  class="numbersML">04</span>
                                    <p>Functions offline</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <span  class="numbersML">05</span>
                                    <p>⁠Easy to operate</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <span  class="numbersML">06</span>
                                    <p>⁠No training or complicated setup required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100 counterAreaH bgOurColor">
    <div class="container">
        <div class="row">
           

            <div class="col-lg-4 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>99.9% uptime</h3>
                    <p>Ensuring uninterrupted access</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>50 million</h3>
                    <p>Data points securely in the past year</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>90%</h3>
                    <p>Reduced paper usage</p>
                </div>
            </div>
            </div>
            <div class="row mt-5">
            <div class="col-lg-4 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>96%</h3>
                    <p>Customer satisfaction rate</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>65%</h3>
                    <p>Faster decision-making process</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>98%</h3>
                    <p>Reduction in manual data entry</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top"><img src="assets/img/counter/counter-shape.png" alt="Images"></div>
</div>

<div class="service-another pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span class="sp-before sp-after">Services</span> -->
            <h2>imonitor capabilities</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">imonitor quality, safety, and standards across business operations
                    </a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">Internal evaluations, self-assessments, compliance verifications</a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">Quality assurance, brand consistency</a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">Occupational health & safety, risk evaluations</a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">Gas station health and safety check</a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">Covid-19 protective measures</a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">⁠Food safety & HACCP regulations</a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">⁠SOP, process evaluation checklists</a></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card capH">
                    <h3><a routerLink="/services-details">ISO quality & safety benchmarks</a></h3>
                </div>
            </div>
        </div>
    </div>

    <div class="service-another-shape">
        <div class="shape-1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-6"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70" id="work-area">
    <div>
        <div class="section-title text-center mt-lg-5">
            <!-- <span class="sp-before sp-after">Working Process</span> -->
            <h2 class="h2-color2">Bridging frontline and headquarters</h2>
            <h4>Enhance team communication and collaboration towards shared objectives. Establish custom workflows and standardize processes across all locations to ensure everyone understands their tasks, methods, and timelines.
            </h4>
        </div>
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-widget-img">
                        <img src="assets/img/about/about-img4.png" alt="">
                    </div>
                </div>
    
                <div class="col-lg-6 col-md-12">
                    <div class="about-widget-content">
                       
                        <!-- <p>At imonitor, we understand the challenges businesses face in managing operations, ensuring compliance, and driving efficiency. That’s why we created imonitor, a web-based platform and mobile application designed to simplify your processes, provide real-time insights, and empower your team to make informed decisions.</p> -->
                        <div class="about-widget-list">
                            <ul>
                                <li >
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <p> ⁠Task administration: assign follow-up actions to appropriate personnel
                                    </p>
                                </li>
                                <li class="mt-3">
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <p> ⁠Plan audits, implement updated procedures</p>
                                </li>
                                <li class="mt-3">
                                    <i class='bx bx-check bgOurColor bgOurColor'></i>
                                    <p>We do not have to be the best, we do have to give our best Ability to learn. Multicultural competence.</p>
                                </li>
                            </ul>
                        </div>
                        <div class="about-video">
                            <a href="../../../../../assets/imonitor3.mp4" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mission-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="mission-content">
                    <div class="section-title">
                        <!-- <span class="sp-before sp-after">Mission</span> -->
                        <h4 class="h2-color2">Instant transparency, exceptional understanding
                            What are the three most common recurring challenges in your operational processes? Leverage gathered information to obtain unparalleled insights: track performance and patterns; recognize and tackle persistent issues</h4>
                    </div>
                    <!-- <p>Lo  rem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tetur adipisicing saliqua. </p> -->
                    <div class="row">
                        <div class="col-lg-12 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check bgOurColor bgOurColor'></i>
                                <span>01</span>
                                <p>Track all activities in one dynamic dashboard</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check bgOurColor bgOurColor'></i>
                                <span>02</span>
                                <p>⁠Identify patterns, compare site/team performance, examine details</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check bgOurColor bgOurColor'></i>
                                <span>03</span>
                                <p>Make data-driven decisions to improve your business</p>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="mission-img">
                    <img src="assets/img/mission-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>

    <div class="mission-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
    </div>
</div>


<!-- <div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Blogs</span>
            <h2 class="h2-color2">Latest Valuable Insights</h2>
            <p class="margin-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>09 April 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Home of Technology is in Front of You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 July 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>19 June 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div> -->

<!-- <div class="brand-logo-area">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="data-table-area ">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content ">
                    <h2>We Like to Start Your Project With Us</h2>
                    <a  href="../../../../../assets/imonitor4.mp4" class="default-btn popup-btn">Play Video <i class='flaticon-forward'></i></a>
                    <br>

                    <a routerLink="/contact" class="default-btn">Get started <i class='bx bx-plus'></i></a>

            
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/serve-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/facebook_profile_image.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/ICS Logo.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

