
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul> -->
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <!-- <span class="sp-before sp-after">About Us</span> -->
                        <h2>Control Your Business With Our Solutions</h2>
                    </div>
                    <p>Welcome to imonitor, where innovation meets operational excellence. We are a technology-driven company dedicated to simplifying complex business processes through intuitive and efficient solutions. Our flagship product, imonitor, empowers organizations across diverse industries to collect, analyze, and act on critical data, driving growth and compliance with confidence.</p>
               
                    <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>90 %</h3>
                    <p>Savings From  Compliance</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>Tailored to You</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>24/7</h3>
                    <p>Support</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10x</h3>
                    <p>Faster Audit</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span class="sp-before sp-after">Services</span> -->
            <h2>Our Journey’s Path</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6 col-md-6">
                <div class="service-list-content">
                    <h3>Our Mission</h3>
                    <p>Our mission is simple: to enable businesses to operate smarter, safer, and more sustainably. By combining cutting-edge technology with a user-centric approach, we help our clients turn data into actionable insights, improving their efficiency and decision-making.
                    </p>
               
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="service-list-content">
                    <h3>Our Vision</h3>
                    <p>We envision a world where businesses are empowered to achieve excellence through seamless integration of technology, fostering growth, sustainability, and innovation across industries.</p>
                 
                </div>
            </div>

        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span class="sp-before sp-after">Working Process</span> -->
            <h2 class="h2-color2">Our Values</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Integrity <span>1</span></h3>
                            <p>Building trust through transparency and accountability.
                            </p>
                        </li>
                        <li class="text-end">
                            <h3>Excellence <span>2</span></h3>
                            <p>Committed to delivering top-tier solutions that exceed expectations. </p>
                        </li>
                        <li class="text-end">
                            <h3>Innovation <span>3</span></h3>
                            <p>Embracing creativity and forward thinking to drive change. </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Collaboration</h3>
                            <p>Partnering with our clients to achieve mutual success. </p>
                        </li>
                        <li>
                            <h3><span>5</span>Sustainability</h3>
                            <p>Championing responsible business practices that protect the environment. </p>
                        </li>
                        <!-- <li>
                            <h3><span>6</span>Communicate Results</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span class="sp-before sp-after">Our History</span> -->
            <h2>The Facts About Our Company</h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Our Story</h2>
                    <p>Founded with the vision of solving real-world operational challenges, Imonitor has grown into a trusted leader in the Software as a Service (SaaS) industry. Our journey began with the simple idea that businesses need smarter tools to manage their complex workflows.
                        Since then, we have developed an imonitor, a platform that simplifies data collection and streamlines processes, and provides actionable insights to help businesses across industries thrive in a competitive landscape.
                        From our humble beginnings to our current global reach, we remain committed to delivering value, innovation, and excellence to clients.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation </p> -->
                    <!-- <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2> 
        </div>
        <div class="row mt-5">
            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/mission-img.png" alt="Images">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Our Team</h2>
                    <p>Behind every solution, we offer a dedicated team of innovators, engineers, and customer success professionals who work tirelessly to bring your vision to life. We believe that fostering a collaborative environment encourages creativity and excellence at every level.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation </p> -->
                    <!-- <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

           
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
    
        <div class="row pt-45">
       
            <div class="col-lg-6 col-md-6">
                <div class="text-center">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Sustainability Commitment</a></h3>
                    <p>At Imonitor, we believe that technology is a powerful tool for sustainability. Through an imonitor, we enable businesses to imonitor and reduce their environmental impacts, helping them align themselves with global sustainability goals.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="text-center">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Our Global Impact</a></h3>
                    <p>Today, imonitors are trusted by businesses in various regions and industries. By helping small enterprises optimize their operations to enable large corporations to manage multiple facilities, we are proud to make a difference on a global scale.</p>
                </div>
            </div>
        </div>
    </div>
</div>



